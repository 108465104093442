import "./index.css";

import { enableIEpolyfills } from "../helpers/polyfill";

enableIEpolyfills();

document.addEventListener("DOMContentLoaded", (event) => {
  let htmlElement = document.documentElement;
	htmlElement.classList.add('JS');
	htmlElement.classList.remove('no-JS');

  /** There is a page after filling single donate form, which we want to hide from users so that it goes
   * straight to opayo/elavon payment page rather than user having to press submit again, so below will submit on domLoad
   * as long as js is on client side otherwise this page will show with js off and user will have to press submit to pay" */
  const paymentHiddenSubmitButton = document.querySelector(".selectSubmit");
  if (paymentHiddenSubmitButton) {
    paymentHiddenSubmitButton.submit();
  }
});
